import { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import TopBar from "../topbar/TopBar";
import Intro from "../intro/Intro";
import Portfolio from "../Portfolio/Portfolio";
import Skills from "../Skills/Skills";
import Education from "../Education/Education";
import Contact from "../Contact/Contact";
import "./home.scss";
import Menue from "../menue/Menue";
import CharityPage from "../CharityPage/CharityPage";

function Home() {
  return (
    <div className="sections">
      <Intro />
      <Portfolio />
      <Education />
      <Skills />
      <Contact />
    </div>
  );
}

export default Home;
