export const NGO = [
  {
    id: 1,
    tool: "React.js",
    imgImp: "assets/NGO/NGOWeb1.png",
    descImp:
      "After the prototype, the first usability testing have been made to test out the efficiency and preference of the users. Based on the prototype, the design have been implemented with React.js. This is the home page of the website.",
  },
  {
    id: 2,
    tool: "React.js",
    imgImp: "assets/NGO/NGOWeb2.png",
    descImp:
      "In the About Us page, here you can find the activities of what the organization have been done and also the volunteers of this organization.",
  },
  {
    id: 3,
    tool: "React.js",
    imgImp: "assets/NGO/NGOWeb3.png",
    descImp:
      "Here is the page which shows the kids who are in need. Beside the basic information of the kids, there are also some background story of the children. The user can select the kid which they would like to donate in this page.",
  },
  {
    id: 4,
    tool: "React.js",
    imgImp: "assets/NGO/NGOWeb4.png",
    descImp:
      "After selecting the kid, the payment page will ask the user to provide some baseic payment information. Furthermore, the user can also select different payment plans, such as, monthly, yearly or one time donate.",
  },
];

export const COVID = [
  {
    id: 1,
    tool: "React.js",
    imgImp: "assets/COVID/COVIDWeb1.png",
    descImp:
      "The implementation was done with React.js. The home page implementation was trying to be as similar as the prototype. It basically gives the user a opportunity to have an overview of the website.",
  },
  {
    id: 2,
    tool: "React.js",
    imgImp: "assets/COVID/COVIDWeb2.png",
    descImp:
      "The Dashboard page is one of the main function of the website. The data was connected with an api to get real time numbers. The map provide the user to switch between bubble map and heat map. While the user click on a specific region on the map, the numbers of the courty will pop up. The right side of the design is to show the number rating around the world. The line chart takes 10 days of the data to visualize, it can provide the user an easy way to check whether the number is increasing or decreasing.",
  },
  {
    id: 3,
    tool: "React.js",
    imgImp: "assets/COVID/COVIDWeb3.png",
    descImp:
      "The Forum page provide the user to post the information which is related to Covid-19. The user will be able to post an article or give a review in the article.",
  },
  {
    id: 4,
    tool: "React.js",
    imgImp: "assets/COVID/COVIDWeb4.png",
    descImp:
      "Last but not least, the News page seperate the information into three different categories, Global news, courtry news and Opinions from the professionals. So the user can easily find the information they need with the help of these categories.",
  },
];

export const FridgeWeb = [
  {
    id: 1,
    tool: "Next.js",
    imgImp: "assets/FridgeWeb/FridgeWeb1.png",
    descImp:
      "The implementation was done with Next.js. The default home page is also to show the user their own food storage, the user will be able to delete or add items in this section. While the food is near the expiration date, the application will also provide the recommand recipe for the user.",
  },
  {
    id: 2,
    tool: "Next.js",
    imgImp: "assets/FridgeWeb/FridgeWeb2.png",
    descImp:
      "This is the recipe detail page, after going into the recipe page and select the recipe which you like, this is the page which will display the different ingredients and also the description of how the food can be made.",
  },
  {
    id: 3,
    tool: "Next.js",
    imgImp: "assets/FridgeWeb/FridgeWeb3.png",
    descImp:
      "The shopping list page has followed the prototype, it provide differnet sections for the user to add and delete the ingredients which they would like to purchase in the future. After buying the food, the user will be able to directly input the ingredients into their food storage page in this section.",
  },
  {
    id: 4,
    tool: "Next.js",
    imgImp: "assets/FridgeWeb/FridgeWeb4.png",
    descImp:
      "The Feed page remain the same as the prototype design. The user can either post or browse through different posts from other users. Then if the user find anything interesing, they can directly text the ingredint provider. The text will be display in the message section.",
  },
];

export const FridgeMobile = [
  {
    id: 1,
    tool: "React Native",
    imgImp: "assets/FridgeMobile/FridgeMobileApp1.png",
    descImp:
      "Due to the reviews from the testing, there are some display changing in the implementation compare to the prototype. But basically, it is still showing the basic function of the home page.",
  },
  {
    id: 2,
    tool: "React Native",
    imgImp: "assets/FridgeMobile/FridgeMobileApp2.png",
    descImp:
      "In the food storage page, the design and most of the function remain the same as the prototype design. Only one new feature is that the user can not only add items by typing, but also using the camera to take picture of the item to add into their storage. The function was connected with Google vision API to do the image analyze.",
  },
  {
    id: 3,
    tool: "React Native",
    imgImp: "assets/FridgeMobile/FridgeMobileApp3.png",
    descImp:
      "The shoppign list page design has added some border color next to each different category, which will be able to let the user differentiate the categories easier.",
  },
  {
    id: 4,
    tool: "React Native",
    imgImp: "assets/FridgeMobile/FridgeMobileApp4.png",
    descImp:
      "The feed detail page, the user can look into the details of the post and also the location will be displayed in the post, so that the user will be able to know the distance to the provider. While the user is making a post, they can decide whether they would like to show their own location or not, its optional!",
  },
];
