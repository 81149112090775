import { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./componenets/Home/Home";
import TopBar from "./componenets/topbar/TopBar";

import "./app.scss";
import Menue from "./componenets/menue/Menue";
import CharityPage from "./componenets/CharityPage/CharityPage";
import CovidWebPage from "./componenets/CovidWebPage/CovidWebPage";
import FridgeWebPage from "./componenets/FridgeWebPage/FridgeWebPage";
import FridgeMobilePage from "./componenets/FridgeMobilePage/FridgeMobilePage";

function App() {
  const [menueOpen, setMenueOpen] = useState(false);

  return (
    <Router>
      <div className="app">
        <TopBar menueOpen={menueOpen} setMenueOpen={setMenueOpen} />
        <Menue menueOpen={menueOpen} setMenueOpen={setMenueOpen} />

        {/* <Switch>
            <Route path="/">
              <div className="sections">
                <Intro />
                <Portfolio />
                <Education />
                <Skills />
                <Contact />
              </div>
            </Route>
          </Switch> */}
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/NGO%20Web%20App" element={<CharityPage />} />
          <Route path="/Covid-19%20Web%20App" element={<CovidWebPage />} />
          <Route
            path="/Smart%20Fridge%20Web%20App"
            element={<FridgeWebPage />}
          />
          <Route
            path="/Food%20Management%20App"
            element={<FridgeMobilePage />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
