import React, { useState } from "react";
import "./fridgeMobilePage.scss";
import WireFrameDisplay from "../WireFrameDisplay/WireFrameDisplay";

import Contact from "../Contact/Contact";
import PrototypeDisplay from "../PrototypeDisplay/PrototypeDisplay";
import ImplmentationDisplay from "../ImplementationDisplay/ImplementationDisplay";

function FridgeMobilePage() {
  return (
    <div className="fridgeMobilePage">
      <div className="section">
        <WireFrameDisplay title="FridgeMobile" dataOrder="3" />
        <PrototypeDisplay title="FridgeMobile" />
        <ImplmentationDisplay title="FridgeMobile" />
        <Contact />
      </div>
    </div>
  );
}

export default FridgeMobilePage;
