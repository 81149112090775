import React, { useState } from "react";
import "./covidWebPage.scss";
import WireFrameDisplay from "../WireFrameDisplay/WireFrameDisplay";

import Contact from "../Contact/Contact";
import PrototypeDisplay from "../PrototypeDisplay/PrototypeDisplay";
import ImplmentationDisplay from "../ImplementationDisplay/ImplementationDisplay";

function CovidWebPage() {
  return (
    <div className="covidWeb">
      <div className="section">
        <WireFrameDisplay title="COVID" dataOrder="1" />
        <PrototypeDisplay title="COVID" />
        <ImplmentationDisplay title="COVID" />
        <Contact />
      </div>
    </div>
  );
}

export default CovidWebPage;
