import React, { useState } from "react";
import "./charityPage.scss";
import WireFrameDisplay from "../WireFrameDisplay/WireFrameDisplay";

import Contact from "../Contact/Contact";
import PrototypeDisplay from "../PrototypeDisplay/PrototypeDisplay";
import ImplmentationDisplay from "../ImplementationDisplay/ImplementationDisplay";

function CharityPage() {
  return (
    <div className="charity">
      <div className="section">
        <WireFrameDisplay title="NGO" dataOrder="0" />
        <PrototypeDisplay title="NGO" />
        <ImplmentationDisplay title="NGO" />
        <Contact />
      </div>
    </div>
  );
}

export default CharityPage;
