import React, { useState } from "react";
import "./fridgeWebPage.scss";
import WireFrameDisplay from "../WireFrameDisplay/WireFrameDisplay";

import Contact from "../Contact/Contact";
import PrototypeDisplay from "../PrototypeDisplay/PrototypeDisplay";
import ImplmentationDisplay from "../ImplementationDisplay/ImplementationDisplay";

function FridgeWebPage() {
  return (
    <div className="fridgeWebPage">
      <div className="section">
        <WireFrameDisplay title="FridgeWeb" dataOrder="2" />
        <PrototypeDisplay title="FridgeWeb" />
        <ImplmentationDisplay title="FridgeWeb" />
        <Contact />
      </div>
    </div>
  );
}

export default FridgeWebPage;
