import React, { useState, useEffect } from "react";
import "./prototypeDisplay.scss";
import { NGO, COVID, FridgeWeb, FridgeMobile } from "../../detailData";

function PrototypeDisplay({ title }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    switch (title) {
      case "NGO":
        setData(NGO);
        break;
      case "COVID":
        setData(COVID);
        break;
      case "FridgeWeb":
        setData(FridgeWeb);
        break;
      case "FridgeMobile":
        setData(FridgeMobile);
        break;
    }
  }, []);

  const handleClick = (way) => {
    way === "left"
      ? setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : 3)
      : setCurrentSlide(currentSlide < data.length - 1 ? currentSlide + 1 : 0);
  };

  return (
    <div className="charity">
      <div
        className="slider"
        style={{ transform: `translateX(-${currentSlide * 100}vw)` }}
      >
        {data.map((data) => (
          <div className="container">
            <div className="itemLeft">
              <div className="leftPage">
                <div className="pageTitle">
                  <h2
                    style={{
                      fontSize: "30px",
                      marginBottom: "10px",
                      color: "whitesmoke",
                    }}
                  >
                    Prototype - Adobe xd
                  </h2>
                </div>
                <div className="textContent">
                  <div className="textContainer">
                    <p style={{ fontSize: "20px" }}>{data.descPro}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="right">
                <img src={data.imgPro} alt="" />
              </div>
            </div>
          </div>
        ))}
      </div>
      {currentSlide > 0 ? (
        <img
          src="assets/image.png"
          alt=""
          className="arrow left"
          onClick={() => handleClick("left")}
        />
      ) : null}

      <img
        src="assets/image.png"
        alt=""
        className="arrow right"
        onClick={() => handleClick()}
      />
    </div>
  );
}

export default PrototypeDisplay;
