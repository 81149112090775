import { useEffect, useState } from "react";
import PortfolioList from "../portfolioList/PortfolioList";
import "./portfolio.scss";
import { webPortfolio, mobilePortfolio } from "../../data";

export default function Portfolio() {
  const [selected, setSelected] = useState("web");
  const [data, setData] = useState([]);

  const list = [
    {
      id: "web",
      title: "Web App",
    },
    {
      id: "mobile",
      title: "Mobile App",
    },
  ];

  useEffect(() => {
    switch (selected) {
      case "web":
        setData(webPortfolio);
        break;
      case "mobile":
        setData(mobilePortfolio);
        break;

      default:
        setData(webPortfolio);
    }
  }, [selected]);

  return (
    <div className="portfolio" id="portfolio">
      <h1>Portfolio</h1>
      <ul>
        {list.map((item) => (
          <PortfolioList
            title={item.title}
            id={item.id}
            active={selected === item.id}
            setSelected={setSelected}
          />
        ))}
      </ul>
      {/* <div className="container">
        {data.map((data) => (
          <div className="item">
            <img src={data.img} alt="" />
            <h3>{data.title}</h3>
          </div>
        ))}
      </div> */}
      {/* <div className="container">
        <div className="itemLeft"></div>
        <div className="itemRight">
          {data.map((data) => (
            <div className="item">
              <img src={data.img} alt="" />
              <h3>{data.title}</h3>
            </div>
          ))}
        </div>
      </div> */}
      <div className="container">
        {data.map((data) => (
          <div className="itemContainer">
            <div className="itemLeft">
              <h1>{data.title}</h1>
              <h3>{data.desc}</h3>
              <a href={"/" + data.title}>Learn more</a>
            </div>
            <div className="itemRight">
              <img src={data.img} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
