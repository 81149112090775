export const webPortfolio = [
  {
    id: 1,
    title: "NGO Web App",
    img: "./assets/NGO.png",
    desc: "This is a web application for NGO purpose, to make the way of donation easier.",
  },
  {
    id: 2,
    title: "Covid-19 Web App",
    img: "./assets/covidWebApp.png",
    desc: "During the COVID-19 pandamic, I have created a website which provide the user to check the numbers of cases, recovered, death on country based.",
  },
  {
    id: 3,
    title: "Smart Fridge Web App",
    img: "./assets/fridgeApp.png",
    desc: "This web application is specifically design for smart fridge to manage their food storage and also give a posibility to share their food with the comunity.",
  },
];

export const mobilePortfolio = [
  {
    id: 1,
    title: "Food Management App",
    img: "./assets/fridgeMobileApp-removebg-preview.png",
    desc: "This is application is to help the user to manage thier food storage. A food sharing function has also be included to help reducing the waste of food.",
  },
];
