export const NGO = [
  {
    id: 1,
    imgPro: "assets/NGO/NGOPrototype1.png",
    descPro:
      "The desing was made with Adobe xd and based on the wireframe and color theory. This is the home page of the design. Basically in the home page, some basic information and design will be used to catch the users and also show some information about the website.",
  },
  {
    id: 2,
    imgPro: "assets/NGO/NGOPrototype2.png",
    descPro:
      "Going in to the Sponsor A Child page, various of children information will be displayed in the sigt. The user will be able to select the children based on their background story. Furthermore, the user can choose to donate to the child.",
  },
  {
    id: 3,
    imgPro: "assets/NGO/NGOPrototype3.png",
    descPro:
      "After select a child which you would like to donate, in this sight the user will be able to enter their basic information and payment mathod. In addition, the user will also be able to select different payment plan to support the children.",
  },
  {
    id: 4,
    imgPro: "assets/NGO/NGOPrototype4.png",
    descPro:
      "After donat to a child, the website will provide the user an opportunity to write a card for the donated kid to whether encourage or talk to the kid.",
  },
];

export const COVID = [
  {
    id: 1,
    imgPro: "assets/COVID/COVIDPrototype1.png",
    descPro:
      "The home page of the website provide the user to have an overview of the funcionalities which the website provide. Such as, a glence of current COVID-19 numbers, articles related to COVID-19 and also forum.",
  },
  {
    id: 2,
    imgPro: "assets/COVID/COVIDPrototype2.png",
    descPro:
      "Going into the Covid-19 Dashboard, the user should be able to select their region and see the rising or decreasing numbers. The page will also provide line graph to let the user can compare different numbers easiler.",
  },
  {
    id: 3,
    imgPro: "assets/COVID/COVIDPrototype3.png",
    descPro:
      "In the news section, the news will be seperated into three different categories, global new, the news in your country and some opinions from the professionals.",
  },
  {
    id: 4,
    imgPro: "assets/COVID/COVIDPrototype4.png",
    descPro:
      "The forum section should provide the users to talk about the situation of the pandamic. The user will be able to live reviews or post their own articles.",
  },
];

export const FridgeWeb = [
  {
    id: 1,
    imgPro: "assets/FridgeWeb/FridgeWebPrototype1.png",
    descPro:
      "The default main page is to show the user about their own fridge storage. The user will be able to add or delete element inside their storage. When the food is near the expiration date, the display will change into red color to inform the user, furtehrmore, the application will recommand some recipe for the user based on the ingredients which is near the expiration date.",
  },
  {
    id: 2,
    imgPro: "assets/FridgeWeb/FridgeWebPrototype2.png",
    descPro:
      "In the recipe page, the user should be able to see different kinds of recipes, the recipes will be seperated based on region. After select one of the recipe, the details of the ingredients and steps will be shown.",
  },
  {
    id: 3,
    imgPro: "assets/FridgeWeb/FridgeWebPrototype3.png",
    descPro:
      "Due to the reason that the app can be connected to the mobile applicaion, the user can create their shoppign list on either phone or web application. In the last category, the user will be able to create notes to inform themselves while doing the grocery shopping. After the shopping section, the user will be able to either delete the item or directly add the item into their food storage page.",
  },
  {
    id: 4,
    imgPro: "assets/FridgeWeb/FridgeWebPrototype4.png",
    descPro:
      "The world food wasting range is increasing every year, so the website also provide the function which the user will be able to post their near expiration date food or the food which they will not needed in the furture to the Feed section (With the phone application). The user will be able to browse throgh different kinds of food in the Feed section, and to text with the food provider if they find the food interesting.",
  },
];

export const FridgeMobile = [
  {
    id: 1,
    imgPro: "assets/FridgeMobile/FridgeMobilePrototype1.png",
    descPro:
      "The Home page of the application will show the overview of what the application can do with a navigation bar down to let the user to navigate to different page easiler.",
  },
  {
    id: 2,
    imgPro: "assets/FridgeMobile/FridgeMobilePrototype2.png",
    descPro:
      "In the my fridge section, the user will be able to see their own food storage, which is linked to the web application. The users will be able to add or delete items in their storage in this page. The red border square item is informing the user that the food is near the expiration date, and there will be recommand recipe provided.",
  },
  {
    id: 3,
    imgPro: "assets/FridgeMobile/FridgeMobilePrototype3.png",
    descPro:
      "In the shopping list page, the user can either update it on mobile app or web application, the data will be the same based on the account. The user will be able to create their shopping list in this page and also some notes to remind he/her any important information about the shopping. After the grocery shopping, the user will be able to direct input the food they bought into their food storage in this page.",
  },
  {
    id: 4,
    imgPro: "assets/FridgeMobile/FridgeMobilePrototype4.png",
    descPro:
      "With the mobile phone, the user will be able to post a feed or browse through different feed which is posted by other users. This image is showing after selected a feed, the user will be able to see the image of the item, the poster name, description and also the provider location.",
  },
];
