import { useState } from "react";
import "./education.scss";

export default function Works() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const data = [
    {
      id: "1",
      year: "2015-2019",
      title: "National Dong Hwa University",
      subTitle: "Information Management",
      desc: [
        {
          id: "1.1",
          content: "Learning the concept for computer science.",
        },
        {
          id: "1.2",
          content: "Experienced in differnet programimg language.",
        },
        {
          id: "1.3",
          content: "Get a certificate from SAP ECC 6.0.",
        },
        {
          id: "1.4",
          content:
            "Learning management field knowledge. Such as, statistic, management...etc.",
        },
      ],
      img: "./assets/collegePic.png",
    },
    {
      id: "2",
      year: "07/2018-12/2018",
      title: "Taiwan Architecture & Building Center",
      subTitle: "Student Intern",
      desc: [
        {
          id: "2.1",
          content:
            "A internship from Industrial Technology Research Institute.",
        },
        {
          id: "2.2",
          content: "Experience and experiment block chain technology.",
        },
        {
          id: "2.3",
          content: "Building the website for the company.",
        },
        {
          id: "2.4",
          content: "Design thinking trainning.",
        },
      ],
      img: "./assets/digiTalent.png",
    },
    {
      id: "3",
      year: "07/2019-09/2019",
      title: "Baxter",
      subTitle: "Digital Marketing",
      desc: [
        {
          id: "3.1",
          content: "Experience the chat bot for the digital marketing.",
        },
        {
          id: "3.2",
          content: "Learn the usage of AR and VR for the marketing strategy.",
        },
      ],
      img: "./assets/baxter.png",
    },
    {
      id: "4",
      year: "2019-2021",
      title: "SRH Hochschule Heidelberg",
      subTitle: "Applied Computer Science",
      desc: [
        {
          id: "4.1",
          content: "Learning differnet programing language and concept.",
        },
        {
          id: "4.2",
          content: "Specialized in UI/UX",
        },
        {
          id: "4.3",
          content: "Taking course of SAP HANA",
        },
        {
          id: "4.4",
          content: "Learning concept of differnet database system",
        },
      ],
      img: "./assets/master.png",
    },
  ];

  const handleClick = (way) => {
    way === "left"
      ? setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : 3)
      : setCurrentSlide(currentSlide < data.length - 1 ? currentSlide + 1 : 0);
  };

  return (
    <div className="education" id="education">
      <img className="backgroundImage" alt="" src="assets/college.jpg" />

      <h1>Education & Experiences</h1>
      <div
        className="slider"
        style={{ transform: `translateX(-${currentSlide * 100}vw)` }}
      >
        {data.map((data) => (
          <div className="container">
            <div className="item">
              <div className="left">
                <div className="leftContainer">
                  <div className="year">
                    <h2>{data.year}</h2>
                  </div>
                  {/* <div className="imgContainer">
                    <img src={data.icon} alt="" />
                  </div> */}
                  <h2>{data.title}</h2>

                  <h3>{data.subTitle}</h3>
                  {data.desc.map((desc) => (
                    <p> - {desc.content}</p>
                  ))}

                  {/* <span>Projects</span> */}
                </div>
              </div>
              <div className="right">
                <img src={data.img} alt="" />
              </div>
            </div>
          </div>
        ))}
      </div>
      {currentSlide > 0 ? (
        <img
          src="assets/image.png"
          alt=""
          className="arrow left"
          onClick={() => handleClick("left")}
        />
      ) : null}

      <img
        src="assets/image.png"
        alt=""
        className="arrow right"
        onClick={() => handleClick()}
      />
    </div>
  );
}
