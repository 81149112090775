import "./topbar.scss";
import { Person, Mail } from "@material-ui/icons";

export default function TopBar({ menueOpen, setMenueOpen }) {
  const handleMenueOpen = () => {
    setMenueOpen(!menueOpen);
  };

  return (
    <div className={"topbar " + (menueOpen && "active")}>
      <div className="wrapper">
        <div className="left">
          <a href="/" className="logo">
            Yu's Portfolio
          </a>
          <div className="itemContainer">
            <Person className="icon" />
            <span>+49 17632587550</span>
          </div>
          <div className="itemContainer">
            <Mail className="icon" />
            <span>yuwu.work@gmail.com</span>
          </div>
        </div>
        <div className="right">
          <div className="hamburger" onClick={() => handleMenueOpen()}>
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
          </div>
        </div>
      </div>
    </div>
  );
}
