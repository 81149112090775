import { useState, useRef } from "react";
import "./contact.scss";
import emailjs from "emailjs-com";

export default function Contact() {
  const [message, setMessage] = useState(false);

  const form = useRef();

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setMessage(true);
  // };

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_63urb3e",
        "template_eiif3bz",
        e.target,
        "user_g7wJkCg8h1cwslKHCyg9l"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Your email has been succesfully send out :)");
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <div className="contact" id="contact">
      <div className="left">
        <img src="assets/profilePicture.png" alt="" />
        <div className="leftRight">
          <h1 style={{ color: "#02d61b" }}>
            Don't<span style={{ color: "whitesmoke" }}> be hestitate</span>
          </h1>
          <h3 style={{ color: "white" }}>to</h3>
          <h2 style={{ color: "#02d61b" }}>CONTACT ME</h2>
        </div>
      </div>
      <div className="right">
        <h2>Contact</h2>
        <form onSubmit={sendEmail} ref={form}>
          <div>
            <label>Subject:</label>
            <input type="text" placeholder="Subject" name="subject" />
          </div>
          <div>
            <label>Name:</label>
            <input type="text" placeholder="Name" name="name" />
          </div>
          <div>
            <label>Email:</label>
            <input type="email" placeholder="Email" name="email" />
          </div>
          <div>
            <label>Content:</label>
            <textarea placeholder="Message" name="message"></textarea>
          </div>
          <button type="submit">Send</button>
          {message && <span>Thanks, I will reply as soon as possible</span>}
        </form>
        {/* <form ref={form} onSubmit={sendEmail}>
          <label>Name</label>
          <input type="text" name="name" />
          <label>Email</label>
          <input type="email" name="email" />
          <label>Subject</label>
          <input type="text" name="subject" />
          <label>Message</label>
          <textarea name="message" />
          <input type="submit" value="Send Message" />
        </form> */}
      </div>
    </div>
  );
}
