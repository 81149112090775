import "./menue.scss";

export default function Menue({ menueOpen, setMenueOpen }) {
  return (
    <div className={"menue " + (menueOpen && "active")}>
      <ul>
        <li onClick={() => setMenueOpen(!menueOpen)}>
          <a href={"/" + "#intro"}>Home</a>
        </li>
        <li onClick={() => setMenueOpen(!menueOpen)}>
          <a href={"/" + "#portfolio"}>Portfolio</a>
        </li>
        <li onClick={() => setMenueOpen(!menueOpen)}>
          <a href={"/" + "#education"}>Education & Experiences</a>
        </li>
        <li onClick={() => setMenueOpen(!menueOpen)}>
          <a href={"/" + "#skills"}>Skills</a>
        </li>
        <li onClick={() => setMenueOpen(!menueOpen)}>
          <a href={"/" + "#contact"}>Contact</a>
        </li>
      </ul>
    </div>
  );
}
