import React, { useState, useEffect } from "react";
import "./wireFrameDisplay.scss";
import { wireframeContent, firstPageCategory } from "../../wireFrameData";

function WireFrameDisplay({ title, dataOrder }) {
  const [data, setData] = useState([]);
  const [category, setCategory] = useState("");
  const [contentOrder, setContentOrder] = useState(0);

  useEffect(() => {
    const order = Number(dataOrder);
    setData(wireframeContent);

    setContentOrder(order);

    if (title === "NGO" || title === "COVID") {
      setCategory("Wireframe");
    } else {
      setCategory("Project Description");
    }
  }, []);

  return (
    <div className="pageContainer">
      <div className="upPage">
        <h2
          style={{
            fontSize: "30px",
            fontWeight: 900,
            marginBottom: "20px",
            color: "white",
          }}
        >
          {category}
        </h2>
        <div className="textContainer">
          <h3 style={{ fontSize: "20px" }}>{data[contentOrder]}</h3>
        </div>
      </div>

      <div className="downPage">
        <div className="imageContainer">
          <img
            src={
              category == "Wireframe"
                ? "/assets/" + title + "/wireframe.png"
                : "/assets/designThinking.png"
            }
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default WireFrameDisplay;
