import "./skills.scss";

export default function Testimonials() {
  const data = [
    {
      id: 1,
      title: "Licenses",
      img: "./assets/license.png",
      desc: [
        {
          id: 1.1,
          discription: "SAP ECC 6.0",
        },
      ],
    },
    {
      id: 2,
      title: "Coding Skills",
      img: "./assets/coding.png",
      desc: [
        {
          id: 2.1,
          discription: "React.js",
        },
        {
          id: 2.2,
          discription: "Next.js",
        },
        {
          id: 2.3,
          discription: "React Native",
        },
        {
          id: 2.4,
          discription: "SQL",
        },
        {
          id: 2.5,
          discription: "Firebase",
        },
        {
          id: 2.6,
          discription: "MongoDB",
        },
      ],
      featured: true,
    },
    {
      id: 3,
      title: "Languages",
      img: "./assets/languages.png",
      desc: [
        {
          id: 3.1,
          discription: "Chinese (Native)",
        },
        {
          id: 3.2,
          discription: "English",
        },
        {
          id: 3.3,
          discription: "German (learning)",
        },
      ],
    },
  ];

  return (
    <div className="skills" id="skills">
      <h1>Skills</h1>
      <div className="container">
        {data.map((data) => (
          <div className={data.featured ? "card featured" : "card"}>
            <div className="top">
              <img src={data.img} className="user" alt="" />
            </div>
            {data.desc.map((desc) => (
              <div className="center">
                <h4>- {desc.discription}</h4>
              </div>
            ))}

            <div className="bottom">
              <h3>{data.title}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
