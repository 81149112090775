import { useEffect, useRef } from "react";
import "./intro.scss";
import { init } from "ityped";

export default function Intro() {
  const textRef = useRef();

  useEffect(() => {
    init(textRef.current, {
      showCursor: true,
      strings: ["Developer", "Designer"],
      backDelay: 1500,
    });
  }, []);

  return (
    <div className="intro" id="intro">
      <div className="left">
        <div className="imageContainer">
          <img src="assets/profilePicture.png" alt="" />
        </div>
      </div>
      <div className="right">
        <div className="wrapper">
          <h2>Hello there, I'm</h2>
          <h1>Yu Wu</h1>
          <h3>
            Specialized <span ref={textRef} />
          </h3>
        </div>
        <div className="wrapper">
          <h2 style={{ marginBottom: "50px" }}>Well...</h2>
          <h3>
            Hope you enjoy my{" "}
            <h2 style={{ fontSize: "45px", color: "#02d61b" }}>Portfolio</h2>
          </h3>
        </div>
        <a href="#portfolio">
          <p>scroll down</p>
          <img src="assets/downArrow.png" alt="" />
        </a>
      </div>
    </div>
  );
}
